<template>
  <div>
    <v-snackbar
      content-class="centered"
      absolute
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-container fluid class="fill-height">
      <v-row class="header-title-actions">
        <v-col cols="12" sm="6" md="6">
          <h1 class="page-title">{{ $tc("groups.title", 2) }}</h1>
        </v-col>

        <v-col class="align-end" cols="12" sm="6" md="6">
          <v-btn
            color="buttons"
            @click="
              editDialog = true;
              selectedItem = null;
            "
            :loading="loading"
          >
            <v-icon left> fas fa-plus </v-icon>
            {{ $tc("groups.add") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <v-col cols="12" sm="12" md="12">
          <files-table
            @menuClick="handleMenuActions($event)"
            :items="groups"
            :showUsers="true"
            :showScreens="true"
            :showEdit="true"
            :loading="loading"
            :headers="headers"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-if="screensDialog"
      persistent
      scrollable
      v-model="screensDialog"
      max-width="80%"
    >
      <screens :selectedItem="selectedItem" @cancel="screensDialog = false" />
    </v-dialog>

    <v-dialog
      v-if="usersDialog"
      persistent
      v-model="usersDialog"
      max-width="800px"
    >
      <group-users
        :selectedItem="selectedItem"
        @success="handleUsers"
        @cancel="usersDialog = false"
      />
    </v-dialog>

    <v-dialog
      v-if="editDialog"
      persistent
      v-model="editDialog"
      max-width="700px"
    >
      <add-group
        @success="handleSuccess"
        :selectedItem="selectedItem"
        @cancel="editDialog = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import filesTable from "@/components/files-table";
import screens from "./group-screens";
import addGroup from "./edit-group";
import groupUsers from "./group-users";

export default {
  name: "Videos",
  components: {
    filesTable,
    screens,
    addGroup,
    groupUsers,
  },
  data() {
    return {
      groups: [],
      snackbarText: "",
      snackbar: false,
      loading: true,
      screensDialog: false,
      usersDialog: false,
      editDialog: false,
      selectedItem: null,
      headers: [
        {
          text: this.$tc("headers.name"),
          value: "name",
          width: "150px",
        },

        {
          text: this.$tc("headers.users"),
          value: "users",
          width: "150px",
        },
        {
          text: this.$tc("headers.usedStorage"),
          value: "usedStorage",
          width: "150px",
        },

        {
          text: this.$tc("headers.totalStorage"),
          value: "totalStorage",
          width: "150px",
        },
        {
          text: this.$tc("headers.dataTransferUsed"),
          value: "dataTransferUsed",
          width: "150px",
        },
        {
          text: this.$tc("headers.dataTransfer"),
          value: "dataTransfer",
          width: "150px",
        },
        {
          value: "options",
          sortable: false,
          align: "end",
          width: "200px",
        },
      ],
    };
  },

  computed: {
    ...mapState(["groupId"]),
  },

  methods: {
    ...mapActions(["cleanSearch"]),

    handleSuccess() {
      this.snackbarText = this.selectedItem
        ? this.$t("groups.updated")
        : this.$t("groups.added");
      this.snackbar = true;
      this.editDialog = false;
    },

    handleUsers() {
      this.snackbarText = this.$t("groups.users_updated");
      this.snackbar = true;
      this.usersDialog = false;
    },

    handleMenuActions(payload) {
      switch (payload.action) {
        case "show_screens":
          this.screensDialog = true;
          this.selectedItem = payload.item;
          break;

        case "edit":
          this.editDialog = true;
          this.selectedItem = payload.item;
          break;

        case "show_users":
          this.selectedItem = payload.item;
          this.selectedItem.all_users = [];
          let all_users = [];

          for (let i = 0; i < this.groups.length; i++) {
            if (this.groups[i].users)
              all_users = all_users.concat(this.groups[i].users);
          }

          this.selectedItem.all_users = all_users;
          this.usersDialog = true;
          break;
      }
    },
  },

  mounted() {
    fb.auth()
      .currentUser.getIdTokenResult()
      .then(async (result) => {
        let systemAdministrator = result.claims.systemAdministrator
          ? true
          : false;
        if (systemAdministrator) {
          await this.$binding("groups", db.collection("groups"));
          this.loading = false;
        } else {
          return this.$router.push("/");
        }
      });
  },
};
</script>