<template>
  <div>
    <v-snackbar content-class="centered" top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card :loading="loading" class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline">
          {{
            $tc("groups.users.title", selectedItem.name, {
              name: selectedItem.name,
            })
          }}
        </span>
        <v-btn
          style="right: 10px; top: 10px;  border-radius : 30px!important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col v-if="waiting" cols="12" sm="12" md="12">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-skeleton-loader
                    v-bind="attrs"
                    type="image"
                  ></v-skeleton-loader>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-skeleton-loader
                    v-bind="attrs"
                    type="image"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <transfer
                :leftSideTitle="`${$t(
                  'groups.users.without_configured_group'
                )}`"
                :rightSideTitle="`${$t('groups.users.added')}`"
                @changed="usersChanged"
                :loading="loading"
                v-if="!waiting"
                :rightSource="selectedItem.users || []"
                :leftSource="users"
              />
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" md="12">
              <v-row justify="end">
                <v-btn @click="save" :loading="loading" color="buttons">{{
                  $tc("button.save")
                }}</v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import transfer from "@/components/transfer";

export default {
  name: "group-users",
  props: ["selectedItem"],
  components: {
    transfer,
  },

  data() {
    return {
      snackbarText: "",
      snackbar: false,
      loading: true,
      waiting: true,
      users: [],
      selectedUsers: [],
      modifiedList : false
    };
  },

  computed: {
    ...mapState(["user", "groupId"]),
  },

  methods: {
    usersChanged(data) {
      this.modifiedList = true;
      this.selectedUsers = data;
    },
    async save() {
      if ( this.modifiedList ) {
        this.loading = true;

        db.collection("groups")
          .doc(this.selectedItem[".key"])
          .update({
            users: this.selectedUsers,
          })
          .then((res) => {
            this.loading = false;
            this.$emit("success");
          })
          .catch((err) => {
            this.snackbarText = this.$tc("messages.unknown");
            this.snackbar = true;
          });
      } else {
        this.$emit("success");
      }
    },
  },
  mounted() {
    let user_with_assigned_group = this.selectedItem.all_users;

    // By default a user can only be in one group, with this we make sure that a user who is already in a group is not assigned to another.
    user_with_assigned_group = user_with_assigned_group.filter(
      (item) =>
        !this.selectedItem.users || !this.selectedItem.users.includes(item)
    );

    db.collection("users")
      .get()
      .then((response) => {
        response.forEach((item) => {
          let user = item.data();
          user[".key"] = item.id;
          this.users.push(user);
        });

        // only users who do not have an assigned group are shown.
        this.users = this.users.filter(
          (user) => !user_with_assigned_group.includes(user[".key"])
        );

        this.waiting = false;
        this.loading = false;
      })
  },
};
</script>