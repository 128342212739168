<template>
  <div>
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline"
          >{{ selectedItem ? $tc("dialog.edit") : $tc("dialog.add") }}
          {{ $tc("groups.title", 1) }}</span
        >
        <v-btn
          style="right: 10px; top: 10px; border-radius: 30px !important"
          icon
          color="primary"
          fab
          absolute
          :loading="loading"
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-5">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="group.name"
                :label="$tc('groups.name', 1)"
                clearable
                :loading="loading"
                rounded
                filled
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                type="number"
                v-model.number="group.totalStorage"
                :label="$tc('groups.total_storage')"
                clearable
                :loading="loading"
                rounded
                hint="MB"
                persistent-hint
                filled
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                type="number"
                v-model.number="group.dataTransfer"
                :label="$tc('groups.data_transfer')"
                clearable
                hint="MB"
                persistent-hint
                :loading="loading"
                rounded
                filled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0">
            <v-col cols="6" sm="6" md="6">
              <v-switch
                v-model="group.active"
                :loading="loading"
                :label="$tc('dialog.active')"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-row justify="end">
                <v-btn
                  class="mt-7"
                  @click="selectedItem ? update() : save()"
                  :loading="loading"
                  color="buttons"
                  >{{ $tc("button.save") }}</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";

export default {
  name: "add-group",
  props: ["selectedItem"],
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      group: {},
      loading: true,
    };
  },
  computed: {
    ...mapState(["user"]),
  },

  methods: {
    save() {
      if (this.group.name) {
        this.loading = true;
        db.collection("groups")
          .add({
            name: this.group.name,
            dataTransfer: this.group.dataTransfer ? this.group.dataTransfer : 100000,
            totalStorage: this.group.totalStorage ? this.group.totalStorage : 100000,
            createdAt: new Date(),
            createdBy: this.user.uid,
            screensAllowed: 10,
            active: this.group.active ? true : false,
          })
          .then((res) => {
            this.$emit("success");
            this.loading = false;
          })
          .catch((err) => {
            this.snackbarText = this.$tc("messages.unknown");
            this.snackbar = true;
          });
      } else {
        this.snackbarText = this.$tc("messages.insert_all_fields");
        this.snackbar = true;
      }
    },
    update() {
      if (this.group.name) {
        this.loading = true;
        db.collection("groups")
          .doc(this.selectedItem[".key"])
          .update({
            name: this.group.name,
            dataTransfer: this.group.dataTransfer ? this.group.dataTransfer : 0,
            totalStorage: this.group.totalStorage ? this.group.totalStorage : 0,
            active: this.group.active ? true : false,
            modifiedAt: new Date(),
            modifiedBy: this.user.uid,
          })
          .then((res) => {
            this.$emit("success");
            this.loading = false;
          })
          .catch((err) => {
            this.snackbarText = this.$tc("messages.unknown");
            this.snackbar = true;
          });
      } else {
        this.snackbarText = this.$tc("messages.insert_all_fields");
        this.snackbar = true;
      }
    },
  },
  mounted() {
    if (this.selectedItem) {
      this.group = JSON.parse(JSON.stringify(this.selectedItem));
    }
    this.loading = false;
  },
};
</script>
