<template>
  <v-card :loading="loading" class="d-flex flex-column">
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card-title class="grid-close">
      <span class="headline">
        {{
          $tc("groups.screens.title", selectedItem.name, {
            name: selectedItem.name,
          })
        }}</span
      >
      <v-btn
        style="right: 10px; top: 10px; border-radius: 30px !important"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
        v-if="!loading"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              clearable
              filled
              rounded
              v-model="search"
              hide-details
              :label="`${$t('search')} `"
              prepend-inner-icon="fa-search"
              class="d-md-flex mb-5 ml-5 mr-3 search"
            />
          </v-col>

          <v-col
            style="display: flex; justify-content: flex-end"
            cols="12"
            sm="12"
            md="12"
          >
            <v-btn
              @click="addDialog = true"
              color="buttons"
              class="white--text ma-5"
              ><v-icon color="white" class="mr-5">fas fa-plus</v-icon>Agregar
              pantalla</v-btn
            >
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <files-table
              @menuClick="handleMenuActions($event)"
              :items="screens"
              :loading="loading"
              :localSearch="search"
              :showInfo="true"
              :headers="headers"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-dialog
        v-if="statusDialog"
        persistent
        v-model="statusDialog"
        max-width="850px"
      >
        <device-status
          :screen="selectedScreen"
          @success="statusDialog = false"
          @cancel="statusDialog = false"
        />
      </v-dialog>

      <v-dialog
        v-if="addDialog"
        persistent
        scrollable
        v-model="addDialog"
        max-width="650px"
      >
        <!-- <device-status
          :screen="selectedScreen"
          @success="statusDialog = false"
          @cancel="statusDialog = false"
        /> -->
        <add-screen
          @cancel="addDialog = false"
          :group="selectedItem"
          @success="handleSuccess"
        ></add-screen>
      </v-dialog>
    </v-card-text>
  </v-card>

  <!-- </div> -->
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import filesTable from "@/components/files-table";
import deviceStatus from "@/components/device-status";
import addScreen from "./add-screen";

export default {
  name: "group-screens",
  props: ["selectedItem"],

  components: {
    filesTable,
    deviceStatus,
    addScreen,
  },

  data() {
    return {
      snackbarText: "",
      snackbar: false,
      loading: true,
      statusDialog: false,
      selectedScreen: null,
      search: "",
      screens: [],
      addDialog: false,
      agencies: [],
      headers: [
        {
          text: this.$t("headers.name"),
          value: "name",
          width: "150px",
        },
        {
          text: this.$t("headers.agency"),
          value: "agencyName",
        },

        {
          text: this.$t("headers.configured_content"),
          value: "selectedApp",
          width: "250px",
        },
        {
          text: this.$t("headers.createdAt"),
          value: "createdAt",
          width: "200px",
        },
        {
          value: "options",
          sortable: false,
          align: "end",
        },
      ],
    };
  },

  computed: {
    ...mapState(["user", "groupId"]),
  },

  methods: {
    ...mapActions(["cleanSearch"]),

    handleSuccess(e) {
      this.snackbarText = e;
      this.snackbar = true;
      this.addDialog = false;
    },

    handleMenuActions(payload) {
      switch (payload.action) {
        case "screen_status":
          this.selectedScreen = payload.item;
          this.statusDialog = true;
          break;
      }
    },
  },
  async mounted() {
    // console.log(this.selectedItem);

    this.cleanSearch();

    await this.$binding(
      "agencies",
      db
        .collection("agencies")
        .where("groupId", "==", this.selectedItem[".key"])
    );

    await this.$binding(
      "screens",
      db.collection("screens").where("groupId", "==", this.selectedItem[".key"])
    );

    this.screens.forEach((e) => {
      let agency = this.agencies.find((item) => item[".key"] == e.agency);
      e.agencyName = agency ? agency.name : "";
    });

    this.loading = false;

    // db.collection("screens")
    //   .where("groupId", "==", this.selectedItem[".key"])
    //   .get()
    //   .then((response) => {
    //     this.screens = [];

    //     response.forEach((item) => {

    //       // let screen = item.data();
    //       // screen[".key"] = item.id;
    //       // let agency = this.agencies.find(
    //       //   (item) => item[".key"] == screen.agency
    //       // );
    //       // screen.agencyName = agency ? agency.name : "";

    //       // this.screens.push(screen);
    //     });

    //     this.loading = false;
    //   });
  },
};
</script>