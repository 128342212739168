<template>
  <v-card :loading="loading">
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card-title class="grid-close">
      <span class="headline"> Agregar pantalla</span>
      <v-btn
        style="right: 10px; top: 10px; border-radius: 30px !important"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
        v-if="!loading"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="code"
                :label="$tc('screens.code')"
                rounded
                :rules="[rules.required]"
                :loading="loading"
                ref="code"
                filled
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="screen.name"
                :rules="[rules.required]"
                :label="$tc('dialog.name')"
                rounded
                :loading="loading"
                filled
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-textarea
                v-model="screen.description"
                :label="$tc('dialog.description')"
                rows="2"
                :loading="loading"
                rounded
                filled
                clearable
              ></v-textarea>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-autocomplete
                v-model="selectedApp"
                :items="apps"
                :label="$t('agencies.screens.select_content')"
                item-text="name"
                :rules="[rules.required]"
                item-value="url"
                @change="getItems"
                :loading="loading"
                :no-data-text="$t('table.no_data_text')"
                filled
                rounded
                :allow-overflow="false"
                clearable
              >
                <template v-slot:selection="data">
                  {{ data.item.name }}
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        data.item.name
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        >{{ data.item.description }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-autocomplete
                item-text="name"
                item-value="id"
                label="Seleccionar contenido"
                rounded
                filled
                :rules="[rules.required]"
                :items="items"
                v-model="source"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-autocomplete
                item-text="name"
                item-value=".key"
                label="Seleccionar agencia"
                rounded
                :rules="[rules.required]"
                filled
                :items="agencies"
                v-model="selectedAgency"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header class="mx-0 px-0">
                    <b> Configuraciones avanzadas</b>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12" sm="4" md="4">
                        <v-switch
                          v-model="screen.rotate"
                          label="Rotar pantalla"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" sm="8" md="8">
                        <v-select
                          rounded
                          filled
                          :disabled="!screen.rotate"
                          label="Angulo de rotación"
                          hint="En sentido de las manecillas del reloj"
                          v-model="screen.rotateDegrees"
                          :items="rotation"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-select
                          :items="aspect"
                          item-text="name"
                          rounded
                          filled
                          item-value="value"
                          v-model="screen.aspect"
                          :label="$t('zones.adjust_multimedia_content')"
                        >
                          <template v-slot:selection="data">
                            <v-list-item-title>{{
                              data.item.name
                            }}</v-list-item-title>
                          </template>
                          <template v-slot:item="data">
                            <template>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  data.item.name
                                }}</v-list-item-title>
                                <small>{{ data.item.description }}</small>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions
      style="display: flex; justify-content: flex-end"
      class="pa-5"
    >
      <!-- <v-row>
        <v-col cols="12" sm="12" md="12"> -->
      <v-btn :loading="loading" @click="save" color="buttons">{{
        $tc("dialog.save")
      }}</v-btn>
      <!-- </v-col>
      </v-row> -->
    </v-card-actions>
  </v-card>
</template>
  
  <script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";

export default {
  name: "add-group-screens",
  props: ["group"],

  data() {
    return {
      snackbarText: "",
      snackbar: false,
      selectedAgency: "",
      loading: true,
      selectedApp: "",
      source: "",
      items: [],
      rules: {
        required: (value) => !!value || this.$t("rules.required"),
        positive: (value) => value > 0 || this.$t("rules.positive"),
        min: (v) => (v && v.length >= 3) || "Mínimo 8 caracteres",
        email: (v) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "Correo Invalído";
        },
      },
      aspect: [
        {
          name: this.$t("zones.contain"),
          value: "contain",
          description: this.$t("zones.contain_description"),
        },
        {
          name: this.$t("zones.fill"),
          value: "fill",
          description: this.$t("zones.fill_description"),
        },
        {
          name: this.$t("zones.cover"),
          value: "cover",
          description: this.$t("zones.cover_description"),
        },
      ],
      apps: [
        {
          name: this.$t("apps.player.name"),
          description: this.$tc("apps.player.description"),
          url: "player",
        },
        {
          name: this.$t("apps.multizone.name"),
          description: this.$tc("apps.multizone.description"),
          url: "zones",
        },
        // {
        //   name: this.$t("apps.directories.name"),
        //   description: this.$tc("apps.directories.description"),
        //   url: "directories",
        // },
        {
          name: this.$t("apps.survey.name"),
          description: this.$tc("apps.survey.description"),
          url: "survey",
        },

        {
          name: this.$t("apps.trivia.name"),
          description: this.$tc("apps.trivia.description"),
          url: "trivia",
        },

        {
          name: this.$t("apps.roulette.name"),
          description: this.$tc("apps.roulette.description"),
          url: "roulette",
        },
      ],
      rotation: [
        {
          text: "90 grados",
          value: 90,
        },
        {
          text: "180 grados",
          value: 180,
        },

        {
          text: "270 grados",
          value: 270,
        },
      ],
      //   statusDialog: false,
      //   selectedScreen: null,
      //   search: "",
      //   screens: [],
      screen: {},
      code: "",
      //   addDialog: false,
      agencies: [],
      headers: [
        {
          text: this.$t("headers.name"),
          value: "name",
          width: "150px",
        },
        {
          text: this.$t("headers.agency"),
          value: "agencyName",
        },

        {
          text: this.$t("headers.configured_content"),
          value: "selectedApp",
          width: "250px",
        },
        {
          text: this.$t("headers.createdAt"),
          value: "createdAt",
          width: "200px",
        },
        {
          value: "options",
          sortable: false,
          align: "end",
        },
      ],
    };
  },

  computed: {
    ...mapState(["user", "groupId"]),
  },

  methods: {
    ...mapActions(["cleanSearch"]),

    handleSnackbar(msg) {
      this.snackbarText = msg;
      this.snackbar = true;
      this.loading = false;
    },

    async save() {
      let isValid = await this.$refs.form.validate();

      if (!isValid) {
        this.snackbarText = "Debe completar todos los campos.";
        this.snackbar = true;
        return;
      }

      try {
        this.loading = true;

        let screensResult = await db
          .collection("tempScreens")
          .where("code", "==", parseInt(this.code))
          .get();

        if (!screensResult.size) {
          this.handleSnackbar(
            `${this.$tc("messages.screen_code_not_found", this.code, {
              code: this.code,
            })}`
          );
          return;
        }

        if (!screensResult.docs[0].data().uuid) {
          this.handleSnackbar(this.$tc("messages.empty_screen"));
          return;
        } else {
          let ref = await db.collection("screens").add({
            name: this.screen.name,
            description: this.screen.description ? this.screen.description : "",
            createdAt: new Date(),
            createdBy: this.user.uid,
            active: true,
            uuid: screensResult.docs[0].data().uuid,
            groupId: this.group[".key"],
            rotate: this.screen.rotate ? this.screen.rotate : false,
            aspect: this.screen.aspect ? this.screen.aspect : "",
            rotateDegrees: this.screen.rotateDegrees
              ? this.screen.rotateDegrees
              : null,
          });

          await db
            .collection("tempScreens")
            .doc(screensResult.docs[0].id)
            .update({
              configured: true,
            });

          let payload = {
            modifiedAt: new Date(),
            modifiedBy: this.user.uid,
            isScheduled: false,
            selectedApp: this.selectedApp,
            agency: this.selectedAgency,
          };

          switch (this.selectedApp) {
            case "player":
              payload.playlist = this.source;
              payload.defaultPlaylist = this.source;
              break;

            case "zones":
              payload.zone = this.source;
              break;

            case "survey":
              payload.survey = this.source;
              break;

            case "trivia":
              payload.gameId = this.source;
              break;

            case "roulette":
              payload.gameId = this.source;
              break;

            default:
              break;
          }

          await db.collection("screens").doc(ref.id).update(payload);

          this.loading = false;

          this.$emit("success", this.$tc("messages.screen_added"));
        }
      } catch (error) {
        console.log(error);
        this.handleSnackbar(this.$tc("messages.unknown"));
      }
    },

    async getItems(e) {
      this.items = [];
      let query = null;

      switch (e) {
        case "player":
          query = db
            .collection("playlists")
            .where("groupId", "==", this.group[".key"]);
          break;

        case "zones":
          query = db
            .collection("zones")
            .where("groupId", "==", this.group[".key"]);
          break;

        case "survey":
          query = db
            .collection("forms")
            .where("groupId", "==", this.group[".key"]);
          break;

        case "trivia":
          query = db
            .collection("games")
            .where("type", "==", "trivia")
            .where("groupId", "==", this.group[".key"]);
          break;

        case "roulette":
          query = db
            .collection("games")
            .where("type", "==", "roulette")
            .where("groupId", "==", this.group[".key"]);
          break;

        default:
          break;
      }

      await this.$binding("items", query);

      this.items = this.items.map((e) => {
        return {
          name: e.name ? e.name : e.title,
          id: e[".key"],
        };
      });
    },
  },
  async mounted() {
    this.cleanSearch();

    await this.$binding(
      "agencies",
      db.collection("agencies").where("groupId", "==", this.group[".key"])
    );

    // await this.$binding(
    //   "screens",
    //   db.collection("screens").where("groupId", "==", this.group[".key"])
    // );

    // this.screens.forEach((e) => {
    //   let agency = this.agencies.find((item) => item[".key"] == e.agency);
    //   e.agencyName = agency ? agency.name : "";
    // });

    this.loading = false;
  },
};
</script>